import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { manageError } from '../../api/api';
import { Dispatch, useContext, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { getTipologieScadenziario } from '../../api/apiPagoPa/notifichePA/api';
import { getProfilo } from '../../reusableFunction/actionLocalStorage';
import { ActionReducerType } from '../../reducer/reducerMainState';
import { GlobalContext } from '../../store/context/globalContext';

const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

interface ModalScadenziario {
    setOpen:React.Dispatch<React.SetStateAction<boolean>>,
    open:boolean,
    nonce:string,
    dispatchMainState:Dispatch<ActionReducerType>,
}

interface Scadenziario {
    annoContestazione: number,
    dataFine:string,
    dataInizio:string
    meseContestazione: string
}

const ModalScadenziario : React.FC<ModalScadenziario> = ({setOpen, open, nonce,dispatchMainState}) => {

    const globalContextObj = useContext(GlobalContext);
    const {mainState} = globalContextObj;

    const token =  mainState.profilo.jwt;
    const profilo =  mainState.profilo;

    const handleClose = () => setOpen(false);

    const [datiScadenziario, setDatiScadenziario] = useState<Scadenziario[] | []>([]);
    
    const getScadenziario = async () => {

        getTipologieScadenziario(token, nonce )
            .then((res)=>{
                setDatiScadenziario(res.data);
              
            })
            .catch(((err)=>{
                manageError(err,dispatchMainState);
            }));
    };

    useEffect(()=>{
        if(nonce !== ''){
            getScadenziario();
        }
       
    },[nonce]);

    return (
        <div>
        
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='d-flex justify-content-between'>
                        <div className='ms-3 mt-auto mb-auto'>
                          
                            <Typography  id="modal-modal-title" variant="h6" component="h2">
                                Scadenzario
                            </Typography>
                          
                        </div>
                        <div>
                            <Button variant="contained"  onClick={()=> handleClose() }> X </Button>
                        </div>
                    </div>

                    <div className='mt-3'>
                        <TableContainer component={Paper}>
                            <Table   aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Mese</TableCell>
                                        <TableCell align="left">Anno </TableCell>
                                        <TableCell align="left">Data inizio inserimento contestazione</TableCell>
                                        <TableCell align="left">Data fine inserimento contestazione</TableCell>
                                        {(profilo.profilo === 'CON' || profilo.profilo === 'REC') &&
                                        <>
                                            <TableCell align="left">Data inizio contestazione {profilo.profilo === 'REC'?"Recapitista":"Consolidatore"}</TableCell>
                                            <TableCell align="left">Data fine contestazione {profilo.profilo === 'REC'?"Recapitista":"Consolidatore"}</TableCell>
                                        </>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {datiScadenziario.map((row) => (
                                        <TableRow
                                            key={row.meseContestazione}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                                        >
                                            <TableCell align="left" scope="row">
                                                {row.meseContestazione}
                                            </TableCell>
                                            <TableCell align="left">{row.annoContestazione}</TableCell>
                                            <TableCell align="left">{row.dataInizio}</TableCell>
                                            <TableCell align="left">{row.dataFine}</TableCell>
                                            {(profilo.profilo === 'CON' || profilo.profilo === 'REC') &&
                                            <>
                                                <TableCell align="left">{row.dataRecapitistaInizio}</TableCell>
                                                <TableCell align="left">{row.dataRecapitistaFine}</TableCell>
                                            </>}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                       
                    </div>
                    
                </Box>
                
            </Modal>
        </div>
    );
};
export default  ModalScadenziario;